import $ from 'jquery';
export default class Price {

    constructor() {
        this._init();
    }

    _init() {
        $('input[name="quantity"]').change(function(){
            var q = jQuery(this).val();
            $('input[name="quantity"]').parent().next().attr('data-quantity', q);
        });

        $(".homepage-hour-rate input").change(function(){
            var qty = $('.homepage-hour-rate input').val();
            var totalPrice = (qty * 12.50).toFixed(2).replace(/(\.00+|00+)$/, '');
            $(".homepage-price").replaceWith( "<span class=\"homepage-price\">$" + totalPrice + "</span>" + "</h2>" );
            $("button.ajax_add_to_cart_homepage").attr("data-quantity",qty);
        });
        $(".homepage-hour-rate input").keyup(function(){
            var qty = $('.homepage-hour-rate input').val();
            var totalPrice = (qty * 12.50).toFixed(2).replace(/(\.00+|00+)$/, '');
            $(".homepage-price").replaceWith( "<span class=\"homepage-price\">$" + totalPrice + "</span>" + "</h2>" );
            $("button.ajax_add_to_cart_homepage").attr("data-quantity",qty);
        });
        $( "#homepage_hour_rate_add_to_cart" ).click(function() {
          $( "span#spinner" ).replaceWith( "<span id=\"loading\">Loading...</span>");
          setTimeout(function() {
            // $("span#loading").hide();
            $( "span#loading" ).replaceWith( "<span id=\"spinner\"></span>");
          }, 4000);
        });
    }

}
