<template>
  <div class="container">
    <v-textarea
      counter
      label="Please input to paragraph"
      v-model="converCasePageParagraph"
      class="mt-5"
      rows="10"
    ></v-textarea>
    <div class="mt-2">
      <v-btn @click="convertCasePageUppercaseClick">Uppercase</v-btn>
      <v-btn class="ms-5" @click="convertCasePageLowercaseClick">lowercase</v-btn>
      <v-btn class="ms-5" @click="convertCasePageCapitalClick">Capital</v-btn>
      <v-btn class="ms-5" @click="convertCasePageCapitalizeFirstLetterClick">Capitalize First Paragraph</v-btn>
      <v-btn class="ms-5" @click="convertRemoveSpaceClick">Remove Spaces</v-btn>
    </div>
  </div>
</template>
<script>
    import {
        capitalizeWords, capitalizeFirstLetter, removeAllSpaces
    } from '../../utility/convert_case';
    export default {
        data: () => ({
            rules: [v => v.length <= 25 || 'Max 25 characters'],
            converCasePageParagraph: '',
        }),
        methods: {
            convertCasePageUppercaseClick(){
                this.converCasePageParagraph = this.converCasePageParagraph.toUpperCase();
            },
            convertCasePageLowercaseClick(){
                this.converCasePageParagraph = this.converCasePageParagraph.toLowerCase();
            },
            async convertCasePageCapitalClick(){
                await this.convertCasePageLowercaseClick();
                this.converCasePageParagraph = capitalizeWords(this.converCasePageParagraph);
            },
            async convertCasePageCapitalizeFirstLetterClick(){
                await this.convertCasePageLowercaseClick();
                this.converCasePageParagraph = capitalizeFirstLetter(this.converCasePageParagraph);
            },
            convertRemoveSpaceClick(){
                this.converCasePageParagraph = removeAllSpaces(this.converCasePageParagraph);
            },
        }
    }
</script>
