import $ from 'jquery';
export default class StickyMenu {

    constructor() {
        this._init();
    }

    _init() {
        document.addEventListener("DOMContentLoaded", function(){
            window.addEventListener('scroll', function() {
                if (window.scrollY > 50) {
                    document.getElementById('sticky-menu').classList.add('fixed-top');
                    const navbar_height = document.querySelector('.navbar').offsetHeight;
                    document.body.style.paddingTop = navbar_height + 'px';
                } else {
                    document.getElementById('sticky-menu').classList.remove('fixed-top');
                    document.body.style.paddingTop = '0';
                }
            });
            document.getElementById('sticky-menu').classList.remove('fixed-top');
            $(".mega-content").hover(function() {
                $(this).addClass("active");
            }, function() {
                $(this).removeClass("active");
            });
            // $('ul#menu-main li.popup a').attr('data-bs-toggle', 'modal');
            // $('ul#menu-main li.popup a').attr('data-bs-target', '#mainmenuModal');
            //
            // $('ul#menu-main li.popup2 a').attr('data-bs-toggle', 'modal');
            // $('ul#menu-main li.popup2 a').attr('data-bs-target', '#mainmenuModal2');
        });

    }

}
