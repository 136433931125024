<template>
  <div>
    <v-progress-linear
      indeterminate
      color="green"
      v-if="loading"
    ></v-progress-linear>
  </div>

</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                count: 0
            }
        },
        mounted() {
            const homepage_hour_rate_add_to_cart = document.getElementById("homepage_hour_rate_add_to_cart");
            homepage_hour_rate_add_to_cart.addEventListener("click", function(event) {
                // If the user presses the "Enter" key on the keyboard
                // if (event.target.id === "homepage_hour_rate_add_to_cart") {
                  this.loading = true;
                //   console.log(event.target.id)
                // }
            });
            // console.log(homepage_hour_rate_add_to_cart.trigger('click'));
        },
    }
</script>

<style scoped>

</style>
