import './bootstrap';
import StickyMenu from './utility/sticky_menu.js';
import Fontawesome from './utility/fontawesome.js';
import Price from './utility/price.js';

new StickyMenu();
new Fontawesome();

import 'bootstrap/dist/js/bootstrap.bundle';

//vue
// window.Vue = require('vue');
import { createApp } from "vue";
import vuetify from './vuetify';

import freeTools from './vue_components/free_tools/convertCase.vue';
import addToCart from './vue_components/homepage/addToCart.vue';

const app = createApp({});
app.component('free_tools-component', freeTools);
app.component('add_to_cart-component', addToCart);
app.use(vuetify);
app.mount('#page');

new Price();
